import React from 'react'
import styled from 'styled-components'

const CalloutBar = ({ calloutContent }) => {
  const CallOutOuterContainer = styled.section`
    padding: 2.5em;
    text-align: center;

    h2 {
      margin-bottom: 0;
    }
  `

  const ContentContainer = styled.div``

  return (
    <CallOutOuterContainer
      className={
        'callout-bg-' +
        calloutContent.backgroundColor.replace(/\s+/g, '-').toLowerCase() +
        ' callout-text-color-' +
        calloutContent.textColor.replace(/\s+/g, '-').toLowerCase()
      }
    >
      <ContentContainer>
        <h2>{calloutContent.title}</h2>
      </ContentContainer>
    </CallOutOuterContainer>
  )
}

export default CalloutBar
