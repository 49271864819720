import React, { useRef } from 'react'
import { useLocation } from '@reach/router'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Button from './button'
import { OPEN_CONTACT_MODAL } from '../consts'
import Popup from 'reactjs-popup'
import { X } from 'react-feather'

import ContactForm from '../components/estimate-form'

const ContentContainer = styled.div``

const ContentRow = styled.div``
const FeaturedItemsOuterWrapper = styled.section`
  padding: 40px 0;
`

const OneColumn = styled.div`
  @media (max-width: 992px) {
    margin-bottom: 1em;
  }
  display: flex;
  padding: 0 8px !important;
`
const ColumnInnerContainer = styled.div`
  background: #fff;
`
const ColumnBody = styled.div`
  padding: 1.25em;
`
const Excerpt = styled.p`
  color: #666;
  font-size: 16px;
`

const ShortExcerpt = styled.p`
  color: #666;
  font-size: 16px;
`
const Heading = styled.h4`
  margin-bottom: 0.25em;
  font-size: 1.45em;
  line-height: 1.3;
`

const FeaturedColumns = (data) => {
  const dataColumnsCount = data.data.map((item, index) => (
    <FeaturedColumn key={index} item={item} columnsCount={data.data.length} />
  ))
  return dataColumnsCount
}

const FeaturedColumn = (data) => {
  const learnMoreRef = useRef(null)
  const buttonProps = {}
  const hasContactModalClass =
    data.item.classNames &&
    data.item.classNames.length > 0 &&
    data.item.classNames.filter((className) => className == OPEN_CONTACT_MODAL)
      .length > 0
  if (hasContactModalClass) {
    buttonProps.ref = learnMoreRef
    if (learnMoreRef.current) {
      // learnMoreRef.current.click = e => e.preventDefault()
    }
  }

  return (
    <OneColumn
      className={
        'feature-extended-image has-animation col-12 col-md-' +
        24 / data.columnsCount +
        ' col-lg-' +
        12 / data.columnsCount
      }
    >
      <ColumnInnerContainer className={'shadow-md'}>
        <GatsbyImage
          image={getImage(data.item.heroImage)}
          style={{ width: '100%', height: '200px', objectFit: 'cover' }}
          alt={data.item.serviceTitle + ' Contractor'}
        />
        <ColumnBody className="brd-clr">
          <span>
            <Heading className="color-dark">
              {data.item.featuredLabel
                ? data.item.featuredLabel
                : data.item.serviceTitle}
            </Heading>

            <ShortExcerpt>{data.item && data.item.shortExcerpt}</ShortExcerpt>
          </span>
          {hasContactModalClass ? (
            <Popup
              trigger={
                <Button
                  {...buttonProps}
                  href={'/' + data.item.slug}
                  color={'primary'}
                  title={'Learn more about ' + data.item.serviceTitle}
                >
                  Learn More
                </Button>
              }
              modal
              closeOnDocumentClick
            >
              {(close) => (
                <div className="p-2 block">
                  <button
                    style={{ borderWidth: '0', float: 'right' }}
                    className="cursor-pointer d-block mt-3 mr-4 p-2 float-right m0-auto m-0-auto button__ButtonOuter-gFbSyU"
                    onClick={() => {
                      close()
                    }}
                  >
                    <X color={'#333'} strokeWidth={'3'} className="d-block" />
                  </button>
                  <div className="p-3 py-4 block">
                    <h2
                      className="mb-1 popupheading"
                      style={{ lineHeight: '1.05' }}
                    >
                      Contact Us
                    </h2>
                    <p
                      style={{
                        color: '#666',
                        lineHeight: '1.5',
                        fontSize: '.9em',
                      }}
                      className="mb-3 popupdesc"
                    >
                      If you would like to schedule a complimentary in-home
                      design consultation and estimate or need more information
                      on a product or service that we offer, please fill out the
                      information below.
                    </p>
                    <ContactForm
                      showServicesList={true}
                      showLabels={true}
                      showPlaceHolders={true}
                      pageLocation={
                        (data.pageProps &&
                          data.pageProps.location &&
                          data.pageProps.location.href) ||
                        'Not provided'
                      }
                    />
                  </div>
                </div>
              )}
            </Popup>
          ) : (
            <Button
              to={data.item.slug}
              color={'primary'}
              title={'Learn more about ' + data.item.serviceTitle}
            >
              Learn More
            </Button>
          )}
        </ColumnBody>
      </ColumnInnerContainer>
    </OneColumn>
  )
}

const FeaturedItems = ({ data }) => {
  return (
    <FeaturedItemsOuterWrapper>
      <ContentContainer className="container">
        <ContentRow className="row">
          <FeaturedColumns data={data} />
        </ContentRow>
      </ContentContainer>
    </FeaturedItemsOuterWrapper>
  )
}
export default FeaturedItems
