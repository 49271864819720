import React from 'react'

export default function HouseIdeaVideo() {
  return (
    <div className="bg-222 video-spotlight py-12">
      <div className="container video-container">
        <h2 className="text-center text-white">
          Full House Remodel &amp; Addition
        </h2>
        <p
          className="text-white text-center mx-auto"
          style={{ maxWidth: '767px' }}
        >
          Here at HOUSEIDEA we use the creativity of combined skills,
          craftsmanship, ideas, Imagination, and teamwork to build an addition
          of over 2000 sq ft and remodel the rest of the existing structure.
          Patterned wallpaper, tile, and carpet energize several areas, while
          earthy elements like wood side tables and rattan accent chairs bring
          warmth and balance. And the variety of exterior entertaining areas
          provides perfect dining, lounging, and as well as entertaining areas
          for kids and parents of different ages.
        </p>
        <div className="mx-auto" style={{ maxWidth: '767px' }}>
          <div className="embed-container">
            <iframe
              className="mx-auto flex"
              src="https://www.youtube.com/embed/HUCY5jIjVEE"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  )
}
